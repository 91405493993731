// dimensions
export const SEGMENT_DIMENSION_ID = 'segment_name_aggregate_group';
export const SEGMENT_DESCRIPTION_DIMENSION_ID = 'segment_description_aggregate_group';
export const CHANNEL_DIMENSION_IDS = ['channel', 'message_channel'];

// metrics
export const SUPPRESSED_SENDS_METRIC_ID = 'campaign_suppressed_sends_aggregate_group';

// reports
export const AI_JOURNEY_PERFORMANCE_REPORT_ID = 'ai_journeys_performance';
export const SEGMENTS_DOMAIN_CAMPAIGN_REPORT_IDS = [
  'campaign_performance_by_segment',
  'campaign_aggregate_performance_by_segment',
];

// report classes
export const IDENTITY_AI_REPORT_CLASS = 'Identity AI Class';

// report domains
export const AI_PRO_DOMAIN_ID = 'ai_pro';
export const CAMPAIGNS_DOMAIN_ID = 'campaigns';
export const SEGMENTED_CAMPAIGNS_DOMAIN_ID = 'campaigns_aggregate_group';
export const BILLING_DOMAIN_ID = 'billing';
export const CONCIERGE_DOMAIN_ID = 'concierge';
export const EMAIL_ONLY_REPORT_DOMAINS = [
  'ai_pro_email_only',
  'billing_email_only',
  'campaigns_email_only',
  'campaigns_aggregate_group_email_only',
  'journeys_email_only',
  'program_email_only',
  'subscribers_and_sign_up_units_email_only',
];
export const STANDARD_REPORT_DOMAINS = [
  'ai_pro',
  'billing',
  'campaigns',
  'campaigns_aggregate_group',
  'concierge', // no email only equivalent, should hide entirely
  'journeys',
  'program',
  'subscribers_and_sign_up_units',
];
