import React from 'react';

import {
  SortIcon,
  SortableHeaderCellProps,
  TableCellProps,
  TableProps,
  styled,
} from '@attentive/picnic';

const cellPadding = '$space4 $space1';
const ENTER_KEYS = [' ', 'Enter'];

const cellAlignVariants = {
  left: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
};

const HeaderCellPrimitive = styled('div', {
  padding: cellPadding,
  fontWeight: '$bold',
  borderBottom: '$borderWidths$borderWidth1 solid $borderDefault',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  variants: {
    align: cellAlignVariants,
  },
  defaultVariants: {
    align: 'left',
  },
});

const HeaderCellFocusWrapper = styled('div', {
  display: 'flex',
  '&:hover': { color: '$textHover', cursor: 'pointer' },
  '&:focus': { color: '$textDefault' },
  '&:hover:focus': { color: '$textHover' },
  '&:hover > [data-sort-icon], &:focus > [data-sort-icon]': { visibility: 'visible' },
  focusVisible: '$focus',
});

const triggerKeyHandler = (handler: () => void) => (event: React.KeyboardEvent) => {
  if (ENTER_KEYS.includes(event.key)) {
    event.preventDefault();
    event.stopPropagation();
    handler();
  }
};

const HeaderCell = React.forwardRef<HTMLDivElement, TableCellProps>(
  ({ children, css, ...rest }: TableProps, ref) => (
    <HeaderCellPrimitive ref={ref} role="columnheader" css={css} {...rest}>
      {children}
    </HeaderCellPrimitive>
  )
);

export const SortableHeaderCell = React.forwardRef<
  HTMLDivElement,
  SortableHeaderCellProps & { disabled?: boolean }
>(
  (
    {
      children,
      isSortActive = false,
      ascending = false,
      css = {},
      onChange,
      align,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const getAriaSortValue = () => {
      if (!isSortActive) return undefined;
      return ascending ? 'ascending' : 'descending';
    };

    return (
      <HeaderCell
        ref={ref}
        align={align}
        aria-sort={getAriaSortValue()}
        css={{ ...css, cursor: disabled ? 'not-allowed' : 'pointer' }}
        {...rest}
      >
        <HeaderCellFocusWrapper
          tabIndex={0}
          role="button"
          onClick={onChange}
          onKeyDown={triggerKeyHandler(onChange)}
          css={{ pointerEvents: disabled ? 'none' : 'auto' }}
        >
          <SortIcon
            aria-hidden={true}
            ascending={ascending}
            visible={true}
            data-sort-icon
            css={{
              visibility: isSortActive ? 'visible' : 'hidden',
              flexShrink: 0,
              mt: '-1px',
              mr: '$space1',
            }}
          />
          {children}
        </HeaderCellFocusWrapper>
      </HeaderCell>
    );
  }
);
