/**
 * @generated SignedSource<<6d78a99025974bbb538fab02c10f249a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAiProReportingStates_ReportingPlatform_query$data = {
  readonly aiProSubscriptionCompanyData: {
    readonly aiProSubscriptionStatus?: {
      readonly status: {
        readonly status: {
          readonly __typename: "AiProSubscriptionStatusChurned";
        } | {
          readonly __typename: "AiProSubscriptionStatusPaused";
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "useAiProReportingStates_ReportingPlatform_query";
};
export type useAiProReportingStates_ReportingPlatform_query$key = {
  readonly " $data"?: useAiProReportingStates_ReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAiProReportingStates_ReportingPlatform_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAiProReportingStates_ReportingPlatform_query",
  "selections": [
    {
      "alias": "aiProSubscriptionCompanyData",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GetAiProSubscriptionStatusPayload",
              "kind": "LinkedField",
              "name": "aiProSubscriptionStatus",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AiProSubscriptionStatus",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": (v0/*: any*/),
                          "type": "AiProSubscriptionStatusChurned",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v0/*: any*/),
                          "type": "AiProSubscriptionStatusPaused",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "53713e04859d7cbb6b75c9901a1fba93";

export default node;
