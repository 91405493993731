import React from 'react';

import { Box, Table, Text, Tooltip } from '@attentive/picnic';

import { MetricRawRow } from '../../hooks';

import { StyledBodyCell } from './StyledBodyCell';

interface TotalsRowProps {
  row: MetricRawRow;
  periodDateLabel: string;
  periodLabel: string;
  totalLabel?: string;
  totalLabelDescription?: string;
}

const TOOLTIP_CSS = {
  textDecoration: 'underline dashed',
  textDecorationThickness: '2px',
  textUnderlineOffset: '2px',
  '&:hover': {
    textDecoration: 'underline solid',
  },
};
export const TotalsRow = ({
  row,
  periodDateLabel,
  periodLabel,
  totalLabel = 'Total',
  totalLabelDescription = '',
}: TotalsRowProps) => {
  const useTooltipHeader = !!totalLabelDescription;

  return (
    <Table.BodyRow key="currentPeriodTotalsRow">
      {row.map((cell, columnIndex) => {
        const isLeftAligned = cell.isGroupingDimensionValue;
        const isFirstGroupingColumn = cell.isGroupingDimensionValue && columnIndex === 0;
        const value = isFirstGroupingColumn ? (
          <Box>
            {useTooltipHeader ? (
              <Tooltip>
                <Tooltip.Trigger>
                  <Text variant="caption" css={{ ...TOOLTIP_CSS, width: 'fit-content' }}>
                    {totalLabel}
                  </Text>
                </Tooltip.Trigger>
                <Tooltip.Content>{totalLabelDescription}</Tooltip.Content>
              </Tooltip>
            ) : (
              <Text variant="caption">{totalLabel}</Text>
            )}
            <Text variant="caption" color="subdued" css={{ fontWeight: '$regular' }}>
              {periodDateLabel}
            </Text>
            {periodLabel && (
              <Text variant="caption" color="subdued" css={{ fontWeight: '$regular' }}>
                {periodLabel}
              </Text>
            )}
          </Box>
        ) : (
          cell.value
        );

        const sticky = columnIndex === 0;
        return (
          <StyledBodyCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell: currentPeriodTotalsRow, ${columnIndex} ${cell.value}`}
            align={isLeftAligned ? 'left' : 'right'}
            alignment={isLeftAligned ? 'left' : 'right'}
            type="emphasized"
            stickyLeft={sticky}
            data-analytics-id={isFirstGroupingColumn ? 'totals-row-label' : ''}
          >
            {value}
          </StyledBodyCell>
        );
      })}
    </Table.BodyRow>
  );
};
