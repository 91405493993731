import _useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable from "./__generated__/useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable.graphql";
import _useGenerateReportMetricsColumnHeaders_ReportingPlatform_report from "./__generated__/useGenerateReportMetricsColumnHeaders_ReportingPlatform_report.graphql";
import _useGenerateReportMetricsColumnHeaders_ReportingPlatform_company from "./__generated__/useGenerateReportMetricsColumnHeaders_ReportingPlatform_company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { MetricConnotation as MetricConnotationEnum, MetricDataType } from '../services';
import { useVisibleMetrics } from './useVisibleMetrics';
import CompanyFragment from './__generated__/useGenerateReportMetricsColumnHeaders_ReportingPlatform_company.graphql';
import MetricsTableFragment from './__generated__/useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable.graphql';
import ReportFragment from './__generated__/useGenerateReportMetricsColumnHeaders_ReportingPlatform_report.graphql';
_useGenerateReportMetricsColumnHeaders_ReportingPlatform_company;
_useGenerateReportMetricsColumnHeaders_ReportingPlatform_report;
_useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable;
export const useGenerateReportMetricsColumnHeaders = _ref => {
  let {
    companyRef,
    reportRef,
    metricsTableRef,
    filterDimensions,
    groupingDimensions
  } = _ref;
  const companyData = useFragment(CompanyFragment, companyRef);
  const reportData = useFragment(ReportFragment, reportRef);
  const metricsTableData = useFragment(MetricsTableFragment, metricsTableRef);
  const visibleMetrics = useVisibleMetrics({
    companyRef: companyData,
    reportRef: reportData,
    metricsTableRef: metricsTableData,
    reportFilters: filterDimensions,
    reportGroupings: groupingDimensions
  }); // the first columns will be grouping dimensions starting with date (if it exists)

  const sortedGroupingDimensions = [...groupingDimensions].sort(_ref2 => {
    let {
      dimensionId
    } = _ref2;
    return dimensionId === 'date' ? -1 : 1;
  }); // get the grouping dimension names from the metrics since they're not in the report

  const groupedDimensionHeaders = sortedGroupingDimensions.map(_ref3 => {
    let {
      dimensionId
    } = _ref3;
    let dimensionName = '';
    let dimensionDescription = '';
    let dimensionDataType = MetricDataType.MetricDataTypeUnknown;
    let dimensionConnotation = MetricConnotationEnum.MetricConnotationUnknown;
    visibleMetrics.forEach(_ref4 => {
      let {
        definition: {
          dimensionWrappers,
          dataType,
          connotation
        }
      } = _ref4;
      dimensionWrappers.forEach(_ref5 => {
        let {
          dimension
        } = _ref5;

        if ('dimensionId' in dimension && dimension.dimensionId === dimensionId) {
          dimensionName = dimension.name;
          dimensionDescription = (dimension === null || dimension === void 0 ? void 0 : dimension.description) || '';
          dimensionDataType = dataType;
          dimensionConnotation = connotation;
        }
      });
    });
    return {
      name: dimensionName,
      description: dimensionDescription,
      isGroupingDimensionValue: true,
      dataType: dimensionDataType,
      connotation: dimensionConnotation,
      dimensionId
    };
  }); // get the metrics dimension names

  const metricDimensionHeaders = visibleMetrics.map(metric => ({
    name: metric.definition.name,
    description: metric.definition.description,
    isGroupingDimensionValue: false,
    dataType: metric.definition.dataType,
    connotation: metric.definition.connotation,
    metricId: metric.definition.metricId
  })) || []; // combine the grouping and metrics dimension names and filter out groupings without names

  const columnHeaders = [...groupedDimensionHeaders, ...metricDimensionHeaders].filter(column => column.name);
  return columnHeaders;
};