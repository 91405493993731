export enum ReportTableSortDirection {
  REPORTS_SORT_DIRECTION_ASC = 'REPORTS_SORT_DIRECTION_ASC',
  REPORTS_SORT_DIRECTION_DESC = 'REPORTS_SORT_DIRECTION_DESC',
}

export type ReportTableSortOrder = {
  dimensionId: string | null;
  metricId: string | null;
  sortDirection: ReportTableSortDirection;
  sortRank: number;
};
