import React, { useState } from 'react';
import ReactLinesEllipsis from 'react-lines-ellipsis';

import { ToastType, useToast } from '@attentive/acore-utils';
import { Box, IconButton, Table, Tooltip } from '@attentive/picnic';

import { MetricRawDataCell, MetricRawRow } from '../../hooks';

import { StyledBodyCell } from './StyledBodyCell';

const BodyCellTooltipContent = ({ cellValue }: { cellValue: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [createToast] = useToast();
  return (
    <Box css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Box css={{ marginBottom: '$space2' }}>{cellValue}</Box>
      <Box css={{ alignSelf: 'flex-end' }}>
        <IconButton
          iconName={isCopied ? 'Checkmark' : 'Copy'}
          description="Copy cell content to clipboard."
          variant="inverted"
          size="extraSmall"
          css={{ cursor: 'pointer' }}
          onClick={() => {
            navigator.clipboard.writeText(cellValue);
            setIsCopied(true);
            createToast({
              text: 'Table cell content copied to clipboard.',
              type: ToastType.Success,
            });
          }}
        />
      </Box>
    </Box>
  );
};

const BodyCell = ({
  cell,
  columnIndex,
  rowIndex,
}: {
  cell: MetricRawDataCell;
  columnIndex: number;
  rowIndex: number;
}) => {
  const [isClamped, setIsClamped] = useState(false);
  const isLeftAligned = cell.isGroupingDimensionValue;
  const sticky = columnIndex === 0;
  return (
    <StyledBodyCell
      align={isLeftAligned ? 'left' : 'right'}
      alignment={isLeftAligned ? 'left' : 'right'}
      data-analytics-id={`report-table-row-${rowIndex}-cell`}
      stickyLeft={sticky}
      type="plain"
    >
      <Tooltip>
        <Tooltip.Trigger>
          <Box>
            <ReactLinesEllipsis
              text={String(cell.value)}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
              onReflow={({ clamped }) => setIsClamped(clamped)}
            />
          </Box>
        </Tooltip.Trigger>
        {isClamped && (
          <Tooltip.Content side="top" css={{ pointerEvents: 'auto', wordBreak: 'break-word' }}>
            <BodyCellTooltipContent cellValue={String(cell.value)} />
          </Tooltip.Content>
        )}
      </Tooltip>
    </StyledBodyCell>
  );
};

interface BodyRowProps {
  row: MetricRawRow;
  rowIndex: number;
}

export const BodyRow = ({ row, rowIndex }: BodyRowProps) => {
  return (
    <Table.BodyRow>
      {row.map((cell, columnIndex) => {
        return (
          <BodyCell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell: ${rowIndex}, ${columnIndex}, ${cell.value}`}
            cell={cell}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
          />
        );
      })}
    </Table.BodyRow>
  );
};
